<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form inline label-position="right" label-width="60px" size="small" class="query-form">
        <el-form-item label="搜索">
          <el-input v-model="key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selList();">查询</el-button>
          <el-button type="success" @click="dialogVisible=true;form={isEnable:true};">新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>用户列表</span>
        </div>
        <!-- 列表定义 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width:100%;"
        >
          <el-table-column label="头像" width="100px">
            <template slot-scope="scope">
              <img :src="scope.row.photo" :style="{width:'50px',borderRadius:'50%'}">
            </template>
          </el-table-column>
          <el-table-column prop="realName" label="姓名"></el-table-column>
          <el-table-column prop="mobile" label="手机号"></el-table-column>
          <el-table-column prop="department" label="部门"></el-table-column>
          <el-table-column prop="stateName" label="状态"></el-table-column>
          <el-table-column label="是否可用" prop="isEnable">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isEnable" type="success">是</el-tag>
              <el-tag v-else type="warn">否</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sys_Role.name" label="所属角色"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip v-if="scope.row.sys_Role.isSys==false" content="编辑" placement="top">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="editClick(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip v-if="scope.row.sys_Role.isSys==false" content="删除" placement="top">
                <el-button
                  type="warning"
                  size="mini"
                  icon="el-icon-delete"
                  @click="delClick(scope.row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top:20px;">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50,100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- 操作对话框 -->
    <el-dialog
      :title="form.id>0?'编辑用户信息':'添加用户信息'"
      :visible.sync="dialogVisible"
      @opened="dialogOpended();"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-form-item
          label="姓名"
          prop="realName"
          :rules="[
        {required:true,message:'请输入姓名'}
        ]"
        >
          <el-input v-model="form.realName" placeholder="请输入姓名"></el-input>
        </el-form-item>

        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="form.sex">
            <el-radio :v-model="true" :label="true">男</el-radio>
            <el-radio :v-model="false" :label="false">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="选择图片">
          <div id="uploadBtn3" class="uploader uploader-warning"></div>
          <img
            id="imgTarget"
            :src="form.photo"
            style="width:50px;position:relative;left:10px;top:20px;"
          >
        </el-form-item>

        <el-form-item
          label="登录名"
          prop="loginName"
          :rules="[
        {required:true,message:'请输入登录名'}
        ]"
        >
          <el-input v-model="form.loginName" placeholder="请输入登录名"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>

        <el-form-item label="部门" prop="department">
          <el-input v-model="form.department" placeholder="请输入部门"></el-input>
        </el-form-item>

        <el-form-item label="职位" prop="position">
          <el-input v-model="form.position" placeholder="请输入职位"></el-input>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="状态" prop="state" :rules="[{required:true,message:'请选择状态'}]">
              <el-select v-model="form.state" placeholder="请选择状态" filterable>
                <el-option
                  v-for="item in statelist"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="是否可用">
              <el-switch v-model="form.isEnable"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属角色" prop="roleID" :rules="[{required:true,message:'请选择所属角色'}]">
              <el-select v-model="form.roleID" placeholder="请选择所属角色" filterable>
                <el-option
                  v-for="item in roList"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit">提交</el-button>
          <el-button @click="dialogVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";

export default {
  data() {
    return {
      key: "",
      query: {}, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      id: 0,
      roList: [], //角色列表
      statelist: [{ id: 1, name: "在职" }, { id: -1, name: "离职" }],
      domain: url.getDomain()
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    getList: function() {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      params.key = _this.key;
      var link = url.getSys("getlist_manager");
      $.get(link, params, data => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //角色列表
    roleList: function() {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      params.key = _this.key;
      var link = url.getSys("getlist_role");
      $.get(link, params, data => {
        _this.loading = false;
        _this.roList = data.list;
      });
    },
    //搜索查询
    selList: function() {
      this.currentPage4 = 1;
      this.getList();
    },
    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getSys("addorupdate_manager");
      var info = this.form;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submiting = true;
          //提交后台添加
          //添加或者修改
          $.post(link, info, res => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //编辑
    editClick: function(row) {
      this.form = Object.assign({}, row);
      this.dialogVisible = true;
    },
    //删除
    delClick: function(row) {
      var _this = this;
      var link = url.getSys("delete_manager"); //获取请求地址
      var id = row.id; //获取对象id

      this.$confirm("确定要删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          $.post(link, { idList: id }, res => {
            _this.btnLoading = false;
              if (res.status) {
                  _this.msg = res;
                  _this.getList();
                  this.$message({
                      type: "success",
                      message: "删除成功!"
                  });
              } else {
                  this.$message({
                      type: "error",
                      message: res.msg
                  });
              }
          });
     
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    dialogOpended() {
      var _this = this;
      /**大小图片前台处理，后台简单处理模式，上传成功后显示图片**/
      $("#uploadBtn3").uploader({
        url: "ws://" + url.getHost() + "/upload/common",
        fileExts: "jpg;png",
        text: "设置头像",
        more: false, //是否可以上传多个
        auto: true, //是否自动上传
        type: "dialog", //指定处理类型
        subfolder: "content", //默认后台处理简单模式 ，指定子文件夹
        coverParams: {
          title: "设置头像",
          targetWidth: 150,
          targetHeight: 150
        },
        onSuccess: function(data) {
          console.info(data);
          //添加到列表,每个图片上传成功都会触发
          var src = _this.domain + data.relativeName;
          $("#imgTarget").attr("src", src);
          _this.form.photo = data.relativeName;
        }
      });
    }
  },
  mounted() {
    //获取服务器列表
    this.getList();
    //获取角色列表
    this.roleList();
  }
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
